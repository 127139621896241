@charset "UTF-8";
@font-face {
  font-family: "Bahnschrift";
  font-style: normal;
  src: local("Bahnschrift"), url("../font/BAHNSCHRIFT.TTF") format("truetype");
}
.SP {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .PC {
    display: none !important;
  }
  .SP {
    display: block !important;
  }
}
body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "游ゴシック", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "メイリオ", Meiryo, Helvetica, sans-serif;
  font-size: 1rem;
  color: #000;
  line-height: 2.5;
  -webkit-font-smoothing: antialiased;
  min-width: 1280px;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 0.9rem;
    line-height: 2;
  }
}
a {
  color: #3865e0;
  text-decoration: none;
}

a:hover, a:focus {
  color: #112a6e;
  text-decoration: underline;
}

h1, h2 {
  line-height: 2.2;
}

h3 {
  line-height: 2.3;
}

h4 {
  line-height: 2.4;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  -webkit-backface-visibility: hidden;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #b5c1cc;
}

button, input, optgroup, select, textarea {
  color: #000;
  line-height: inherit;
}

.mb40 {
  margin-bottom: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #fff;
}

/* humbuger */
.nav-sp {
  z-index: 9999;
}

.drawer_hidden {
  display: none;
}

.drawer_open {
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  right: 0;
  top: 0;
}

.drawer_open span,
.drawer_open span:before,
.drawer_open span:after {
  content: "";
  display: block;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background: #333;
  transition: 0.5s;
  position: absolute;
}

.drawer_open span:before {
  bottom: 8px;
}

.drawer_open span:after {
  top: 8px;
}

#drawer_input:checked ~ .drawer_open span {
  background: rgba(255, 255, 255, 0);
}

#drawer_input:checked ~ .drawer_open span::before {
  bottom: 0;
  transform: rotate(45deg);
}

#drawer_input:checked ~ .drawer_open span::after {
  top: 0;
  transform: rotate(-45deg);
}

/*humbuger inner*/
.nav_content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 99;
  background: #fff;
  transition: 0.5s;
}

.nav_list {
  list-style: none;
}

#drawer_input:checked ~ .nav_content {
  left: 0;
}

/*PC NAVI*/
.global-nav-fixed {
  width: 100%;
  position: fixed;
  z-index: 9999;
}

.global-nav img {
  height: 60px;
  width: auto;
  margin: 0 32px 10px 16px;
}

.global-nav-wrap {
  border-left: 1px solid #000;
  padding-left: 32px;
  display: inline-block;
}

.global-nav {
  width: 100%;
  background-color: #fff;
  position: fixed;
  padding: 16px 0 4px;
  z-index: 9999;
  position: relative;
  min-width: 1280px;
}

.global-nav ul {
  float: left;
}

.global-nav ul li {
  padding: 0 40px 0 0;
}

.global-nav ul li a {
  font-size: 16px;
  display: block;
  padding-left: 10px;
  position: relative;
}

.global-nav ul li a:before {
  content: "-";
  position: absolute;
  top: 0;
  left: 0;
}

.global-nav ul li:before {
  width: 4px;
  border-top: 1px solid #000;
}

.global-nav ul li.left_shape {
  margin-top: 20px;
  padding-left: 10px;
  border-left: 1px dotted #555;
}

.global-nav ul li a {
  text-decoration: none;
  color: #333;
}

.global-nav .cr {
  margin-top: 48px;
  font-size: 12px;
}

.season {
  position: absolute;
  right: 100px;
  top: 8px;
}
@media screen and (max-width: 768px) {
  .season {
    position: static;
    margin-top: 50px;
  }
}
.season .seasonList {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  max-width: 100px;
}
@media screen and (max-width: 768px) {
  .season .seasonList {
    flex-wrap: nowrap;
    justify-content: center;
    max-width: 100% !important;
    gap: 10px !important;
  }
}
.season .seasonList__item {
  padding: 0;
  width: 100px;
}
@media screen and (max-width: 768px) {
  .season .seasonList__item {
    width: 112px;
  }
}
.season .seasonList__item a {
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 0;
}
.season .seasonList__item a::before {
  content: none;
}
.season .seasonList__item a.current {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.season .seasonList__item a.non-current {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}
.season .seasonList__item a.non-current:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  transition: 0.5s;
}

.lang {
  position: absolute;
  top: 8px;
  right: 16px;
}

.lang .langList {
  display: flex;
  float: none;
  flex-wrap: wrap;
  gap: 3px;
  max-width: 67px;
}
.lang .langList__item {
  padding: 0;
  width: 32px;
}
.lang .langList__item a {
  font-size: 16px;
  font-family: "Barlow Condensed", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 0;
}
.lang .langList__item a::before {
  content: none;
}
.lang .langList__item a.current {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
}
.lang .langList__item a.non-current {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  box-sizing: border-box;
}
.lang .langList__item a.non-current:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  transition: 0.5s;
}
.lang .langList__item:last-child {
  width: 67px;
}

.dummy {
  width: 900px;
  margin: 48px auto 0;
}

.mainvisual {
  padding: 0 16px;
}

.main {
  padding: 0 16px;
}

.toppage_wrapper h1 {
  margin: 32px 0;
}

.toppage_wrapper h1 img {
  height: 200px;
}

.toppage_wrapper h2 img {
  height: 72px;
}

.toppage_wrapper h3 {
  font-size: 40px;
  font-weight: 900;
}

.banner {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.banner li {
  float: right;
}

/*.banner li:last-child {
  margin-right: -52px;
}*/
.clearfix {
  min-height: 1px;
  zoom: 1;
}

.va {
  height: calc(100vh - 90px);
  position: relative;
  overflow: hidden;
  margin-top: 90px;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.upperpart {
  width: 900px;
  margin: 0 auto;
}

.upperpart p {
  margin-bottom: 60px;
}

.points {
  display: flex;
}

.points-inner {
  position: absolute;
  top: -12vh;
}

.points-bg {
  background-color: #007BCB;
  padding-bottom: 100px;
  position: relative;
  height: 410px;
  margin-top: 210px;
}

.points-position {
  width: 1200px;
  margin: 0 auto;
}

.points_number {
  margin: 20px 0;
}

.between {
  justify-content: space-between;
}

.Itembox {
  width: 346px;
  justify-content: center;
  color: #fff;
}

.text-center {
  text-align: center;
}

.Barlow {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 40px;
  font-weight: normal;
  font-weight: 400;
  line-height: 1.46;
}

.title-heading__heading-text {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.46;
  margin-bottom: 48px;
}

.title-heading__heading-text-extra {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.46;
}

.title-heading__heading-caption-text {
  font-family: "Noto Sans JP", sans-serif;
  color: #272727;
  font-size: 10px;
  font-weight: normal;
  font-weight: 500;
  line-height: 1.46;
}

.sub-heading {
  text-align: left;
}

.sub-heading__text {
  font-family: "Noto Sans JP", sans-serif;
  color: #272727;
  font-size: 14px;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.36;
}

.youtube {
  margin: 48px auto 110px;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#part01 {
  padding: 100px 0;
}

#part02 {
  padding: 100px 0 0;
  margin: 100px auto;
  background-color: #EBF0F1;
}

.inner-fruid {
  width: 1200px;
  margin: 0 auto;
}

.about_yuzawa {
  position: relative;
}

.left-txt-block {
  width: 25%;
  margin-left: 10%;
  margin-right: 3%;
}

.left-txt-block img {
  margin-bottom: 32px;
}

.right-photo-block {
  width: 67%;
}

.right-txt-block {
  width: 25%;
  margin-right: 10%;
  margin-left: 3%;
}

.right-txt-block img {
  margin-bottom: 32px;
}

.left-photo-block {
  width: 67%;
}

.special {
  display: flex;
  margin-bottom: 80px;
}

.between {
  justify-content: space-between;
}

.moreBtn {
  margin-top: 32px;
}

.moreBtn a {
  display: block;
  margin: auto;
  width: 100%;
  height: 40px;
  position: relative;
  border-bottom: 1px solid #2E2E2E;
  line-height: 1;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  color: #000;
}

.moreBtn a::after {
  content: "";
  background: url("../img/ttl-arrow.svg") no-repeat;
  background-size: 32px 10px;
  width: 32px;
  height: 10px;
  position: absolute;
  top: 8px;
  right: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.moreBtn a:hover {
  color: #007BCB;
  filter: alpha(opacity=100);
  opacity: 1;
  text-decoration: none;
}

.moreBtn a:hover::after {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 10px;
}

#part03 {
  padding-bottom: 100px;
}

.table_box {
  margin: auto;
  max-width: 1200px;
  overflow: scroll;
}

.table_box .table_ticket_list {
  width: 100%;
  margin-bottom: 16px;
}

.table_box .table_ticket_list thead tr .deadline {
  font-size: 16px;
  width: 460px;
  height: 48px;
  border-top: solid 1px #000;
}

.table_box .table_ticket_list thead th {
  border-right: solid 1px #000;
  border-left: solid 1px #000;
  border-bottom: solid 1px #000;
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
  padding: 5px 0;
  vertical-align: middle;
  border-top: solid 1px #000;
}

.table_box .table_ticket_list thead th .division {
  width: 175x;
  height: 124px;
}

.table_box .table_ticket_list tbody .title {
  font-size: 18px;
}

.table_box .table_ticket_list tbody td {
  border-right: solid 1px #000;
  border-bottom: solid 1px #000;
  border-left: solid 1px #000;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  height: 48px;
}

.tikit-info {
  display: flex;
  margin-bottom: 32px;
}

.tikit-info-left {
  border-right: 1px solid #000;
  padding-right: 100px;
  font-weight: 600;
}

.tikit-info-right {
  padding-left: 16px;
  font-weight: 600;
}

#part04 {
  padding: 100px 0 100px;
  background-color: #EBF0F1;
}

.ticket_txt {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.5;
}

.tickit-coution {
  padding: 10px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 80px;
}

.bold {
  font-weight: 600;
}

.round-btn {
  font-size: 20px;
  text-align: center;
  margin-top: 48px;
}

.round-btn a {
  display: block;
  margin: 0 auto;
  width: 720px;
  position: relative;
  line-height: 1;
  font-size: 20px;
  color: #fff;
  background-color: #216DA9;
  border-radius: 100px;
  padding: 40px 0;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.round-btn a::after {
  content: "";
  background: url(../img/arrow_right.svg) no-repeat;
  background-size: 11px 22px;
  width: 11px;
  height: 22px;
  position: absolute;
  top: 40%;
  right: 32px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.round-btn a:hover::after {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 40px;
}

.round-btn a:hover {
  background-color: #0F314B;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#part05 {
  padding: 144px 0 100px;
  background-color: #0091D4;
}

.appeal-box {
  background-color: #F8F9DA;
  border: solid 4px #00046F;
  border-radius: 32px;
  padding: 48px 100px;
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: -44px;
}

.appeal {
  background: url(../img/appeal-bg.jpg) no-repeat;
  background-position: bottom;
}

.appeal h3 {
  font-size: 32px;
  font-weight: 600;
  color: #00046F;
  text-align: center;
}

.round-btn-small {
  font-size: 20px;
  text-align: center;
  margin-top: 32px;
}

.round-btn-small a {
  display: block;
  margin: 0 auto;
  width: 485px;
  position: relative;
  line-height: 1;
  font-size: 20px;
  color: #fff;
  background-color: #000;
  border-radius: 60px;
  padding: 20px 0;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
}

.round-btn-small a::after {
  content: "";
  background: url(../img/arrow_right.svg) no-repeat;
  background-size: 11px 22px;
  width: 11px;
  height: 22px;
  position: absolute;
  top: 33%;
  right: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.round-btn-small a:hover::after {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 25px;
}

.round-btn-small a:hover {
  background-color: #216DA9;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.appeal-left {
  width: 600px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  margin-top: 32px;
}

.appeal-right {
  width: 400px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  margin-top: 32px;
}

.appeal_caution {
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
}

.snow-icon {
  width: 80px;
  margin: -40px auto;
  position: relative;
  z-index: 2;
}

#part06 {
  padding: 100px 0 100px;
  background-color: #EBF0F1;
}

.news-inner {
  display: flex;
  margin-bottom: 20px;
}

.news-info {
  background-color: #fff;
  padding: 48px 120px 32px;
  margin-bottom: 20px;
}

.news-heading {
  font-size: 18px;
  font-weight: 600;
  height: 20px;
  padding: 5px 0;
  width: 100px;
  border: 1px solid #000;
  text-align: center;
  margin-right: 16px;
  line-height: 1;
  margin-top: 4px;
}

.news-contents {
  width: 900px;
}

.how-entry {
  margin-top: 60px;
}

.news-inner-b img {
  width: 370px;
}

.news-contents-b {
  width: 470px;
  line-height: 2.5;
}

.news-inner-b {
  display: flex;
  margin-bottom: 20px;
}

.how-checkin {
  margin-top: 60px;
  border: 1px solid #000;
  padding: 48px;
}

.news-info h3 {
  text-align: center;
}

.checkin-number {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 80px;
  width: 60px;
  text-align: center;
  line-height: 1;
}

.checkin-inner {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

#part07 {
  padding: 100px 0;
}

.access img {
  margin-top: 48px;
}

.access-info {
  display: flex;
  margin-top: 48px;
}

.access-info-left {
  border-right: 1px solid #000;
  width: 332px;
  font-weight: 600;
  margin-right: 20px;
}

.access-info-right {
  width: 870px;
}

.access_txt {
  font-size: 20px;
  font-weight: 600;
}

.access_txt-thin {
  font-size: 20px;
}

.access_caution {
  font-size: 14px;
  font-weight: 400;
  margin-top: 48px;
  margin-bottom: 148px;
}

footer {
  padding: 48px 0 !important;
  background-color: #EBF0F1;
  background-size: 100%;
  text-align: center;
}

footer ul {
  width: 100%;
  margin: 16px auto;
  text-align: center;
}

footer ul li {
  display: inline-block;
  padding: 0 40px 0 0;
}

.footer ul li a {
  font-size: 16px;
  display: block;
  padding-left: 10px;
  position: relative;
}

.footer ul li a:before {
  content: "-";
  position: absolute;
  top: 0;
  left: 0;
}

.footer .footer__linkList {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 310px;
}
.footer .footer__linkList__item {
  padding: 0;
}
.footer .footer__linkList__item a {
  padding-left: 0;
}
.footer .footer__linkList__item a::before {
  content: none;
}

.nav-wrap {
  margin: 40px 0;
}

footer ul li:before {
  width: 5px;
  border-top: 1px solid #000;
}

footer ul li.left_shape {
  margin-top: 20px;
  padding-left: 10px;
  border-left: 1px dotted #555;
}

footer ul li a {
  text-decoration: none;
  color: #333;
}

footer .cr {
  margin-top: 48px;
  font-size: 12px;
}

.l-header {
  position: absolute;
  top: 0;
  width: 100%;
}

.c-section__vertical {
  margin-bottom: 48px;
}

.information-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "游ゴシック", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, "メイリオ", Meiryo, Helvetica, sans-serif;
  font-size: 85%;
  line-height: 1.5;
  letter-spacing: 0;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .information-area {
    display: block;
    font-size: 95%;
  }
}
.information-area h3 {
  text-align: center;
  font-weight: normal;
  font-size: 130%;
  margin-bottom: 1.5rem;
  position: relative;
}

.information-area h3::before {
  position: absolute;
  content: "";
  margin: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #999;
}

.information-area h3 span {
  position: relative;
  display: inline-block;
  background: #fff;
  padding: 0 0.5em;
}

.information-area .information-box {
  width: 48%;
}

@media screen and (max-width: 768px) {
  .information-area .information-box {
    width: auto;
    margin-bottom: 1.3rem;
  }
}
.information-area .information-box .information-inner .textarea {
  max-height: 160px;
  overflow: scroll;
  margin-bottom: 10px;
}

.information-area .information-box .information-inner .updatetime {
  text-align: right;
}

.information-area .weather-box {
  width: 48%;
}

@media screen and (max-width: 768px) {
  .information-area .weather-box {
    width: auto;
    margin-bottom: 1.3rem;
  }
}
.information-area .weather-box .weather-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.information-area .weather-box .weather-inner .inner-box {
  width: 33.33333%;
  min-height: 168px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .information-area .weather-box .weather-inner .inner-box {
    min-height: 108px;
  }
}
.information-area .weather-box .weather-inner .inner-box h4 {
  font-size: 130%;
  font-weight: normal;
  margin: 25px 0 10px 0;
}

.information-area .weather-box .weather-inner .inner-box:not(:first-child) {
  border-left: 1px dotted #000;
}

.information-area .weather-box .weather-inner .inner-box:not(:first-child) p {
  padding: 29px 0;
}

@media screen and (max-width: 768px) {
  .information-area .weather-box .weather-inner .inner-box:not(:first-child) p {
    padding: 10px 0;
  }
}
.information-area .weather-box .weather-inner p {
  font-size: 300%;
}

@media screen and (max-width: 768px) {
  .information-area .weather-box .weather-inner p {
    font-size: 200%;
  }
}
.information-area .weather-box .weather-inner p span {
  font-size: 50%;
}

.information-area .livecamera-box {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .information-area .livecamera-box {
    width: auto;
    margin-bottom: 1.3rem;
  }
  .information-area .livecamera-box img {
    width: 100%;
  }
}
.main h3 {
  font-size: 150%;
  padding-bottom: 32px;
}

/*SP*/
@media screen and (max-width: 768px) {
  .dummy {
    width: 100%;
    margin: 48px auto 0;
  }
  body {
    min-width: 320px;
  }
  .va-sp {
    height: 520px;
    margin-top: 0;
  }
  .main {
    padding: 0;
  }
  .mainvisual {
    padding: 0px;
  }
  .main h3 {
    font-size: 100%;
    padding-bottom: 24px;
  }
  .Itembox {
    width: 100%;
    margin-bottom: 32px;
  }
  .Itembox img {
    margin-bottom: 16px;
  }
  .inner-fruid {
    width: 100%;
  }
  .upperpart {
    width: 100%;
  }
  .round-btn a {
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    padding: 16px 0;
  }
  .round-btn-small a {
    width: 90%;
    font-size: 16px;
  }
  .round-btn-small {
    margin-top: 16px;
  }
  .points {
    display: inherit;
  }
  .points-inner {
    position: inherit;
  }
  .points-bg {
    background-color: #007BCB;
    padding-bottom: 0px;
    position: inherit;
    height: auto;
    margin-top: 0;
    padding: 16px;
  }
  .points-position {
    width: 100%;
    margin: 0 auto;
  }
  .points_number {
    margin: 10px 0;
  }
  .appeal-box {
    border-radius: 16px;
    padding: 48px 20px;
    margin-top: -34px;
  }
  .appeal-left {
    width: 100%;
    font-size: 16px;
    margin-top: 24px;
  }
  .news-info {
    padding: 48px 0 32px;
  }
  .how-checkin {
    padding: 28px 20px 16px 20px;
  }
  .news-inner {
    display: inherit;
  }
  .news-contents {
    width: 100%;
    line-height: 1.5;
  }
  .news-contents-b {
    width: 100%;
    line-height: 2.5;
  }
  .news-inner-b img {
    width: 100%;
    margin-top: 16px;
  }
  .news-inner-b {
    display: inherit;
    margin-bottom: 20px;
  }
  .appeal-right {
    width: 100%;
  }
  #part01 {
    padding: 48px 16px;
  }
  #part02 {
    padding: 48px 16px 0;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  #part03 {
    padding: 48px 16px;
  }
  #part04 {
    padding: 48px 16px 48px;
  }
  #part05 {
    padding: 84px 16px;
  }
  #part06 {
    padding: 48px 16px 48px;
  }
  .news-info {
    padding: 48px 16px 32px;
  }
  #part07 {
    padding: 48px 16px;
  }
  .tikit-info-left {
    padding-right: 0;
  }
  .left-txt-block {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .right-txt-block {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .special {
    display: inherit;
    margin-bottom: 40px;
  }
  .toppage_wrapper {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .toppage_wrapper h1 {
    margin: 15px 0;
  }
  .toppage_wrapper h3 {
    font-size: 24px;
    font-weight: 900;
  }
  .toppage_wrapper h1 img {
    height: auto;
  }
  .toppage_wrapper h2 img {
    height: auto;
  }
  .access_txt {
    font-size: 16px;
    line-height: 1.35;
  }
  .access_txt-thin {
    font-size: 16px;
    line-height: 1.35;
  }
  .title-heading__heading-text {
    font-size: 24px;
    margin-bottom: 32px;
  }
  .appeal h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .title-heading__heading-text-extra {
    margin-bottom: 0;
  }
  .checkin-inner {
    margin-bottom: 10px;
  }
  .news-info {
    padding: 32px 16px 32px;
  }
  .news-heading {
    display: block;
    height: 18px;
    padding: 5px 0;
    width: 100%;
    margin-bottom: 8px;
    margin-right: 0;
  }
  .c-section__vertical {
    margin-bottom: 32px;
  }
  .upperpart p {
    margin-bottom: 32px;
  }
  .youtube {
    margin: 32px auto 32px;
  }
  .logo-position {
    text-align: center;
  }
  .right-photo-block {
    width: 100%;
    margin-top: 24px;
  }
  .left-photo-block {
    width: 100%;
    margin-top: 24px;
  }
  .access_caution {
    margin-bottom: 20px;
  }
  .footer img {
    margin-bottom: 24px;
  }
  footer .cr {
    margin-top: 0;
  }
  footer {
    padding: 48px 0 96px !important;
  }
  .ticket_txt {
    font-size: 16px;
    line-height: 1.5;
    margin-right: 8px;
  }
  .snow-icon {
    width: 60px;
    margin: -30px auto;
  }
  .banner {
    padding-left: 16px;
  }
  .banner li:last-child {
    margin-right: 0;
  }
  /*SP NAVI*/
  .global-nav-fixed {
    width: 100%;
    position: fixed;
    z-index: 9999;
  }
  .global-nav img {
    height: 60px;
    width: auto;
    margin: 0 32px 10px 16px;
  }
  .global-nav-wrap {
    border-left: none;
    display: inherit;
    width: 51%;
    margin: 10vh auto;
    padding: 0;
  }
  .global-nav {
    min-width: 100%;
  }
  .global-nav ul {
    float: inherit;
  }
  .global-nav ul li {
    padding: 0 40px 0 0;
  }
  .global-nav ul li a {
    font-size: 16px;
    display: block;
    padding-left: 10px;
    position: relative;
  }
  .global-nav ul li a:before {
    content: "-";
    position: absolute;
    top: 0;
    left: 0;
  }
  .global-nav ul li:before {
    width: 4px;
    border-top: 1px solid #000;
  }
  .global-nav ul li.left_shape {
    margin-top: 20px;
    padding-left: 10px;
    border-left: 1px dotted #555;
  }
  .global-nav ul li a {
    text-decoration: none;
    color: #333;
    margin-bottom: 10px;
  }
  .global-nav .cr {
    margin-top: 48px;
    font-size: 12px;
  }
  .lang-sp {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .lang-sp a {
    font-size: 16px;
    font-family: "Barlow Condensed", sans-serif;
    text-align: center;
    width: 32px;
    display: block;
    margin: 0 4px;
    text-decoration: none;
  }
  .lang-sp a.is_zh {
    width: 67px;
  }
  .current {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
  }
  .non-current {
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    box-sizing: border-box;
  }
  .non-current:hover {
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    transition: 0.5s;
  }
  footer ul {
    width: 80%;
    margin: 16px auto 50px;
    text-align: center;
  }
  footer ul li {
    display: inline-block;
    padding: 0 10px;
  }
  .cr {
    margin-bottom: 50px;
  }
}
/*english*/
.toppage_wrapper-en h1 {
  margin: 32px 0;
}

.toppage_wrapper-en h1 img {
  height: 200px;
}

.toppage_wrapper-en h2 img {
  height: 144px;
}

.points-bg-en {
  background-color: #007BCB;
  padding-bottom: 100px;
  position: relative;
  height: 530px;
  margin-top: 210px;
}

.title-heading__heading-text-en {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 40px;
  line-height: 1.46;
  margin-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .toppage_wrapper-en {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .toppage_wrapper-en h1 {
    margin: 0 0 15px 0;
  }
  .toppage_wrapper-en h1 img {
    height: auto;
  }
  .toppage_wrapper-en h2 img {
    height: auto;
  }
  .points-bg-en {
    background-color: #007BCB;
    padding-bottom: 0px;
    position: inherit;
    height: auto;
    margin-top: 0;
    padding: 16px;
  }
}
/*yukiyama*/
.yukiyamaapi_info_wrap {
  position: relative;
}

.yukiyamaapi_info_bg {
  width: 100%;
}

.yukiyamaapi_info_abs {
  position: absolute;
  width: 100%;
  height: 100%;
}

.yukiyamaapi_info_name {
  z-index: 300;
}

.yukiyamaapi_info_lift134 img, .yukiyamaapi_info_lift136 img, .yukiyamaapi_info_lift156 img {
  z-index: 200;
}

.yukiyamaapi_info_course134 img, .yukiyamaapi_info_course136 img, .yukiyamaapi_info_course156 img {
  z-index: 100;
}

.yukiyamaapi_info_bus {
  z-index: 50;
}

/*230106*/
.round-btn-b {
  font-size: 20px;
  text-align: center;
  margin-top: 48px;
}

.round-btn-b a {
  display: block;
  margin: 0 auto;
  width: 720px;
  position: relative;
  line-height: 1;
  font-size: 20px;
  color: #fff;
  background-color: #FF0000;
  border-radius: 100px;
  padding: 40px 0;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.round-btn-b a::after {
  content: "";
  background: url(../img/arrow_right.svg) no-repeat;
  background-size: 11px 22px;
  width: 11px;
  height: 22px;
  position: absolute;
  top: 40%;
  right: 32px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.round-btn-b a:hover::after {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  right: 40px;
}

.round-btn-b a:hover {
  background-color: #0F314B;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .round-btn-b a {
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    padding: 16px 0;
  }
}